.tbl_updating {
  color: red;
  font-weight: bold;
}

.tbl_done {
  color: green;
  font-weight: bold;
}

.tbl_done_suspect {
  color: rgb(179, 6, 43);
  font-weight: bold;
}

.tbl_bold_text {
  font-weight: bold;
}

.tbl_text_lightgray {
  color: white;
  font-size: 8px;
}
